<div
    class="bg-transparent-black-not-black-30 fixed inset-0 flex size-full"
    @panel
>
    <button
        class="h-full w-3/5"
        type="button"
        [attr.aria-label]="'AI.MINIMIZE' | translate"
        (click)="close()"
    ></button>
    <section
        cdkTrapFocus
        cdkTrapFocusAutoCapture
        class="bg-surface-A flex h-full w-2/5 flex-col gap-y-8 p-12 opacity-0 transition-opacity"
        [ngClass]="{
            'opacity-100': showContent(),
        }"
    >
        <div class="flex w-full items-center justify-end">
            <button
                interacta-icon-button
                type="button"
                [attr.aria-label]="'AI.MINIMIZE' | translate"
                [icon]="'minus'"
                [matTooltip]="'AI.MINIMIZE' | translate"
                (click)="close()"
            ></button>
        </div>
        <div class="grow overflow-hidden">
            <interacta-ai-panel />
        </div>
    </section>
</div>
